import React, { useEffect } from "react"

const GiveawayPage = () => {
  useEffect(() => {
    window.location.href = "https://cdn.honeycrm.com/files/webinar-giveaway.zip";
  })

  return (
    <p>Fetching your giveaway...</p>
  )
}

export default GiveawayPage
